.live-agent {
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  width: 4rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 3rem 3rem;
}

.live-agent:hover {
  background-color: #ebebeb;
}

@media screen and (max-width: 1024px) {
  .live-agent {
    margin: 0 0 4.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .live-agent {
    width: 3.5rem;
    margin: 0 0 2rem 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .live-agent {
    margin: 0 0 6.5rem 20.5rem;
  }
}

@media screen and (max-width: 375px) {
  .live-agent {
    margin: 0 0 0rem 18.5rem;
  }
}

@media screen and (max-width: 320px) {
  .live-agent {
    margin: 0 0 0rem 16.5rem;
  }
}
