@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap");

div {
  font-family: "Space Grotesk", sans-serif !important;
}

.Navbar-pc {
  top: 0;
}

.Navbar-wrapper {
  justify-content: space-between;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  .menu-wrapper {
    display: flex;
    flex-direction: row;
  }

  ul {
    margin: 0;
    padding: 0;
    width: max-content;
  }

  li {
    margin: 0.65rem 0rem;
    text-align: start;
    padding: 0 1rem;
  }

  li:hover {
    background-color: #ebebeb;
  }
}

.Navbar-logo {
  margin-right: 3.7815rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 8rem;
}

.Navbar-menu {
  display: flex;
  flex-direction: row;
  font-family: "Space Grotesk", sans-serif;
  list-style: none;
  padding: 0;
  align-items: center;
}

.desktop-nav-logo{
  width: 8rem;
  margin: -1rem 0;
}

.mobile-nav-2 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff00;
  justify-content: space-between;
  height: 100vh;
}

.mobile-nav {
  z-index: 1000;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  width: 30%;
  background-color: #ffffff;
  justify-content: space-between;

  .menu-button-wrapper {
    display: flex;
    flex-direction: row;
    // align-items: center;
    // justify-content: space-around;
  }

  img {
    width: 1rem;
    margin-left: 0.5rem;
  }

  .submenu-mobile {
    list-style: none;
    display: none;
    flex-direction: column;
    top: 100%;
    left: 0;
    color: black;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 100%;
  }

  .show-submenu-mobile {
    display: flex;
    flex-direction: column;
    // animation: submenu-show 0.5s ease-in-out forwards;
  }
}

.show-mobile-nav {
  // display: flex !important;
  animation: mobile-nav-show 0.5s ease-in-out forwards;
}

.hide-mobile-nav {
  // display: flex !important;
  animation: mobile-nav-hide 0.5s ease-in-out forwards;
}

.menu-button {
  display: inline-block;
  position: relative;
}

.menu-button-item{
  font-size: 1.2rem;
}

.menu-button:hover .submenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.submenu {
  z-index: 1000;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  color: black;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.social-media-icon {
  width: 2.2rem !important;
  background-color: transparent !important;
}

@keyframes mobile-nav-show {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes mobile-nav-hide {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes submenu-show {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
  
}

@keyframes submenu-hide {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 1600px) and (min-width: 769px) {
  .desktop-nav-logo{
    width: 5rem;
  }

  .menu-button-item{
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 769px) {
  .Navbar-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .Navbar-wrapper {
    justify-content: space-between !important;
    width: auto;
    margin: 1rem 0;
    align-items: center !important;
    width: 100% !important;
  }
  .Navbar-logo {
    margin-right: 1.7815rem;
    margin-left: 2.125rem;
  }

  .Navbar-pc {
    display: none !important;
    content-visibility: hidden !important;
  }

  .Navbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    top: 0;
  }

  .menu-button {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .mobile-nav {
    width: 50% !important;
  }

  .Navbar-logo {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 425px) {
  .mobile-nav {
    width: 60% !important;
  }

  .Navbar-logo {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 375px) {
  .mobile-nav {
    width: 70% !important;
  }

  .Navbar-logo {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

// ::-webkit-scrollbar {
//   height: 5px;
//   width: 5px;
//   background: var(--background);
//   -webkit-border-radius: 1ex;
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--accent);
//   -webkit-border-radius: 1ex;
// }

// ::-webkit-scrollbar-corner {
//   background: #fff3;
// }
