.modal-wrapper {
  background-color: #fff;
  height: 40%;
  width: 60%;
}

.modal {
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.78);
  z-index: 1002;
  /* Semi-transparent background */

  .modal-content {
    background: #fff;
    padding: 2.75rem 4.188rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    height: 22.5rem;
    font-family: "Space Grotesk", sans-serif;
  }

  .modal-title {
    left: 0;
    font-size: 1.625rem;
    font-weight: 600;
  }

  .modal-body {
    margin-top: 0.625rem;
    font-size: 1.125rem;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-top: 1.813rem;
    margin-bottom: 3.125rem;
  }

  input {
    width: 36.75rem;
    border: 1px solid #000;
    background-color: transparent;
    outline: none;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.125rem;
    padding: 0 0 0 1.688rem;
    border-radius: 50px;
    height: 3.375rem;
    font-style: to-lower-case($string: "");
  }

  input::placeholder {
    color: #b3b3b3;
  }

  input:focus {
    border: 2px solid #000;
  }

  .below-inputs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .below-text {
    color: #8e8e8e;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 1rem;
  }

  Button {
    background-color: #147cff;
    color: #fff;
    border: none;
    font-size: 1.375rem;
    border-radius: 75px;
    padding: 0.938rem 2.125rem;
    display: flex;

    img {
      width: 1.563rem;
      margin-left: 0.938rem;
    }
  }
}

.modalLiveSupport {
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.78);
  z-index: 1002;
  /* Semi-transparent background */

  .modal-content {
    background: #fff;
    padding: 2.75rem 4.188rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    height: 22.5rem;
    font-family: "Space Grotesk", sans-serif;

    .modal-header{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal-close{
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .modal-title {
    left: 0;
    font-size: 1.625rem;
    font-weight: 600;
  }

  .modal-body {
    margin-top: 0.625rem;
    font-size: 1.125rem;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-top: 1.813rem;
    margin-bottom: 3.125rem;
  }

  input {
    width: 36.75rem;
    border: 1px solid #000;
    background-color: transparent;
    outline: none;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.125rem;
    padding: 0 0 0 1.688rem;
    border-radius: 50px;
    height: 3.375rem;
    font-style: to-lower-case($string: "");
  }

  input::placeholder {
    color: #b3b3b3;
  }

  input:focus {
    border: 2px solid #000;
  }

  .below-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .below-text {
    color: #8e8e8e;
    text-decoration: underline;
    cursor: pointer;
  }

  Button {
    background-color: #147cff;
    color: #fff;
    border: none;
    font-size: 1.375rem;
    border-radius: 75px;
    padding: 0.938rem 6.125rem;
    display: flex;

    img {
      width: 1.563rem;
      margin-left: 0.938rem;
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 769px) {
  .modalLiveSupport{
    .modal-content{
      height: 21.5rem;
      padding: 0.75rem 1.2rem;
    }

    #chatbox {
      margin-top: 76px;
    }

    .modal-body {
      font-size: 1rem;
    }

    .inputs{
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    input{
      font-size: 0.8rem;
      height: 2.8rem;
    }

  }
}

@media only screen and (max-width: 500px) {
  .modal {
    input {
      width: 88%;
    }
    .modal-content {
      width: 90%;
      height: 25.5rem;
      padding: 1.75rem 2.2rem;
    }
  }

  .modalLiveSupport {
    input {
      width: 88%;
    }
    .modal-content {
      width: 90%;
      height: 25.5rem;
      padding: 1.75rem 2.2rem;
    }

    Button{
      display: flex;
      align-items: center;
      width: 14rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      justify-content: center;

      // img {
      //   display: none;
      // }
    }
  }
}
