.welcome-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(212, 212, 212);
  font-size: 2rem;

  .text-big {
    font-size: 3.3rem;
    font-weight: 800;
    margin: 0;
  }

  .lottie {
    width: 26%;
  }

  .error {
    color: red;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
    .welcome-wrapper {
        .text-big {
        font-size: 2.5rem;
        }
        .lottie {
        width: 40%;
        }
    }
}

@media screen and (max-width: 768px) {
  .welcome-wrapper {
    .text-big {
      font-size: 2.5rem;
    }
    .lottie {
      width: 40%;
    }
  }
}

@media screen and (max-width: 480px) {
  .welcome-wrapper {
    font-size: 1.5rem;

    .text-big {
      font-size: 2rem;
    }
    .lottie {
      width: 80%;
    }
  }
}

@media screen and (max-width: 320px) {
  .welcome-wrapper {
    font-size: 1.2rem;
    .text-big {
      font-size: 1.5rem;
    }
    .lottie {
      width: 80%;
    }
  }
}
