.face-one {
    width: max-content;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .eye2,
  .eye1{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .eye-content1{
    width: 100%;
    text-align: center;
  }
  .eye-content2 {
    // width: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    opacity: 0;
  }

  .invisible{
    opacity: 0;
  }

  .visible{
    opacity: 1;
  }