.textbox-wrapper {
  max-width: 50%;

  .textbox {
    background-color: #ebebeb;
    font-size: 1.063rem;
    padding: 0.4rem 1rem;
    margin-bottom: 0.375rem;
    max-width: fit-content;
  }

  .lottie {
    width: 150px;
    // height: 100%;
  }

  p {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .textbox-wrapper {
    max-width: 75% !important;
  }
}

@media screen and (max-width: 500px) {
  .textbox-wrapper {
    margin: 0.1rem 0 !important;
    max-width: 100% !important;
  }
}
