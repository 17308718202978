.chatbox-wrapper {
  width: 100%;

  .chatbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.625rem;
    align-items: flex-end;

    .chatbox-suggestions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .chat {
      height: 3rem;
      border: 1px solid #7d7d7d;
      padding: 0 0.2rem 0 1rem;
      font-weight: 500;
      color: #000000;
      background-color: #f6f6f6;
      outline: none;
      font-family: "Space Grotesk", sans-serif;
      border-radius: 50px;
      display: flex;
      align-items: center;
      min-width: 25%;
    }

    .chat-shrink {
      width: 25%;
      animation: chat-shrink 0.25s ease-in-out forwards;
      box-shadow: -20px 0 20px 30px #f6f6f6;
    }

    .chat-expand {
      width: 100%;
      animation: chat-expand 0.25s ease-in-out forwards;
    }

    .chat:hover {
      border: 1px solid #000000;
    }

    input {
      width: 100%;
      border: none;
      background-color: transparent;
      background-color: #f6f6f6;
      outline: none;
      font-family: "Space Grotesk", sans-serif;
      font-size: 1.125rem;
      padding-left: 2px;
    }

    .send-button {
      border-radius: 100%;
      width: max-content;
      padding: 10px;
      display: flex;
    }

    .send-button-active {
      animation: sendButton-active 0.25s ease-in-out forwards;
    }

    .send-button-deactive {
      animation: sendButton-deactive 0.25s ease-in-out forwards;
    }

    .send-button-active:hover {
      cursor: pointer;
    }

    img {
      width: 1.25rem;
    }

    .chatbox-below-text {
      text-align: left;
      margin: 1.125rem 0 1.125rem 0;
      width: 100%;
    }
  }
}

@keyframes chat-shrink {
  0% {
    width: 100%;
  }

  100% {
    width: 25%;
  }
}

@keyframes chat-expand {
  0% {
    width: 25%;
  }

  100% {
    width: 100%;
  }
}

@keyframes sendButton-active {
  0% {
    background-color: #848484;
  }

  100% {
    background-color: #000;
  }
}

@keyframes sendButton-deactive {
  0% {
    background-color: #000;
  }

  100% {
    background-color: #848484;
  }
}

@media screen and (max-width: 768px) {
  .chatbox-wrapper {

    .chat {
      height: 2.5rem !important;
      padding: 0 0.2rem 0 1rem !important;
    }

    img {
      width: 1rem !important;
    }

    .chatbox {
      margin-top: 0.6rem;

      .chatbox-below-text {
        font-size: 12px;
        margin: 0.45rem 0;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .chat-box-wrapper {
    width: 100% !important;
    margin-bottom: 0 !important;
    height: 100vh;

    img {
      width: 1rem !important;
    }

    .chatbox {
      margin-bottom: 0rem !important;

      .chatbox-below-text {
        font-size: 12px;
        margin: 0.45rem 0;
      }
    }
  }
}
