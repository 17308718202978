.suggestionbox-wrapper{
    border: 1px solid #7D7D7D;
    border-radius: 50px;
    background-color: #FFF;
    cursor: pointer;
    margin: 0 0.64rem;
    width: max-content;
    .suggestionbox{
        padding: 0.64rem;
    }

    .suggestionbox-text{
        font-family: "Space Grotesk", sans-serif;
    }
}

.suggestionbox-wrapper:hover{
    background-color: #000000;
    .suggestionbox-text{
        color: #FFFFFF;
    }
}