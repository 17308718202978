.chatbox-wrapper-mini {
  width: 100%;

  .chatbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    // overflow-x: auto;

    .chat-mini {
      width: 40%;
      height: 2.5rem;
      border: 1px solid #7d7d7d;
      padding: 0 0.1rem 0 1rem;
      font-weight: 500;
      color: #000000;
      background-color: #f6f6f6;
      outline: none;
      font-family: "Space Grotesk", sans-serif;
      border-radius: 50px;
      display: flex;
      align-items: center;
    }

    .chat:hover {
      border: 1px solid #000000;
    }

    input {
      width: 100%;
      height: auto;
      border: none;
      background-color: transparent;
      background-color: #f6f6f6;
      outline: none;
      font-family: "Space Grotesk", sans-serif;
      font-size: 1.125rem;
      padding-left: 2px;
    }

    .send-button {
      border-radius: 100%;
      width: max-content;
      padding: 10px;
      display: flex;
    }

    .send-button-active {
      animation: sendButton-active 0.25s ease-in-out forwards;
    }

    .send-button-deactive {
      animation: sendButton-deactive 0.25s ease-in-out forwards;
    }

    .send-button:hover {
      cursor: pointer;
    }

    .chat-mini {
      img {
        width: 1rem;
      }
    }

    .chatbox-below-text {
      text-align: left;
      margin: 1rem 0 0 1rem;
      width: 100%;
    }
  }
}

@keyframes sendButton-active {
  0% {
    background-color: #848484;
  }

  100% {
    background-color: #000;
  }
}

@keyframes sendButton-deactive {
  0% {
    background-color: #000;
  }

  100% {
    background-color: #848484;
  }
}

@media screen and (max-width: 768px) {
  .chatbox-wrapper {
  }
}

@media screen and (max-width: 425px) {
  .chat-box-wrapper {
    width: 100% !important;
    margin-bottom: 0 !important;
    height: 100vh;

    .chatbox {
      .chat-mini {
        width: 90% !important;
      }
    }
  }
}
